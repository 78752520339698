import React from 'react';
import './Footer.css';
import './Style.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-column footer-company">
        <h3>FormCraft</h3>
        <p>B-224 Kanchan Kunj, Near Kalindi Kunj metro station, Delhi 110076</p>
        <p>Mobile: +91 87000 36746</p>
      </div>
      <div className="footer-column footer-contact">
        <h3>Contact Us</h3>
        <a href="#contact">Contact Us</a>
      </div>
      <div className="footer-column footer-subscribe">
        <h3>Subscribe</h3>
        <input type="email" placeholder="Enter email address" />
        <button>Subscribe</button>
      </div>
      <div className="footer-column footer-social">
        <h3>Get in touch</h3>
        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook"></i></a>
        <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
        <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a>
      </div>
      <div className="footer-watermark">
        <p>Developed by Masood</p>
      </div>
    </footer>
  );
}

export default Footer;
