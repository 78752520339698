import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import project1 from '../elements/project1.jpeg';
import project2 from '../elements/project2.jpeg';
import project3 from '../elements/project3.jpeg';
import project4 from '../elements/project4.jpeg';
import project5 from '../elements/project5.jpeg';
import project6 from '../elements/project6.jpeg';
import './Style.css';
import './Projects.css'; // Create and import CSS for specific component styling

function Projects() {
  const navigate = useNavigate();

  const openGallery = () => {
    navigate('/gallery');
  };

  useEffect(() => {
    const projectTiles = document.querySelectorAll('.project-tile-container');

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('in-view');
            observer.unobserve(entry.target);
          } else {
            entry.target.classList.remove('in-view');
          }
        });
      },
      { threshold: 0.1 } // Adjust the threshold as needed
    );

    projectTiles.forEach(tile => {
      observer.observe(tile);
    });

    return () => {
      projectTiles.forEach(tile => {
        observer.unobserve(tile);
      });
    };
  }, []);

  return (
    <section id="projects" className="projects">
      <h2>Our Projects</h2>
      <div className="project-grid">
        <div className="project-tile-container animated"><img src={project1} alt="Project 1" className="project-tile" onClick={openGallery} /></div>
        <div className="project-tile-container animated"><img src={project2} alt="Project 2" className="project-tile" onClick={openGallery} /></div>
        <div className="project-tile-container animated"><img src={project3} alt="Project 3" className="project-tile" onClick={openGallery} /></div>
        <div className="project-tile-container animated"><img src={project4} alt="Project 4" className="project-tile" onClick={openGallery} /></div>
        <div className="project-tile-container animated"><img src={project5} alt="Project 5" className="project-tile" onClick={openGallery} /></div>
        <div className="project-tile-container animated"><img src={project6} alt="Project 6" className="project-tile" onClick={openGallery} /></div>
      </div>
    </section>
  );
}

export default Projects;
