// emailUtils.js
export const sendEmail = async (form) => {
    const formData = new FormData(form);
  
    const data = {
      service_id: 'service_m1m1h6s',
      template_id: 'template_87zersn',
      user_id: 'oEyTPjEjYhJ_4YhNL',
      template_params: {
        user_name: formData.get('user_name'),
        user_email: formData.get('user_email'),
        user_phone: formData.get('user_phone'),
        message: formData.get('message'),
      },
    };
  
    try {
      console.log('Sending email with data:', data);
      const response = await fetch('https://api.emailjs.com/api/v1.0/email/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      if (response.ok) {
        const textResponse = await response.text();
        console.log('Response:', textResponse);
        alert('Message sent successfully!');
        form.reset();  // Reset the form
      } else {
        const errorText = await response.text();
        console.error('Error:', errorText);
        alert('Failed to send message, please try again later.');
      }
    } catch (error) {
      alert('Failed to send message, please try again later.');
      console.error('Error:', error);
    }
  };
  