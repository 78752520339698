import React from 'react';
import { useNavigate } from 'react-router-dom';
import videoSource from '../elements/background.mp4';
import backgroundImage from '../elements/background-mobile.jpg';
import './Style.css';
import './Home.css'; // Ensure this CSS is created and imported correctly

function Home() {
  const navigate = useNavigate();

  const handleConnectClick = () => {
    navigate('/contact');
  };

  return (
    <section id="home" className="home">
      <video className="background-video" autoPlay loop muted>
        <source src={videoSource} type="video/mp4" />
      </video>
      <img src={backgroundImage} alt="Background" className="background-image" />
      <div className="home-content">
        <h1>Transform your brand presence with FormCraft’s stunning exhibition stands</h1>
        <p>Let’s make your next event unforgettable!</p>
        <button className="connect-button" onClick={handleConnectClick}>Let's Connect</button>
      </div>
    </section>
  );
}

export default Home;
