import React, { useRef } from 'react';
import './Contact.css';
import { sendEmail } from './emailUtils';

function Contact() {
  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    sendEmail(form.current);
  };

  return (
    <section id="contact" className="contact">
      <h2>Get in Touch with Us</h2>
      <div className="contact-content">
        <div className="map">
          <iframe
            title="Company Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3507.710832066364!2d77.31372137603894!3d28.535920582450867!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce3c722f8e1c7%3A0x4813a6185b3ec11d!2sB-224%2C%20Kanchan%20Kunj%2C%20Okhla%20Bird%20Sanctuary%2C%20New%20Delhi%2C%20Delhi%20110076!5e0!3m2!1sen!2sin!4v1693652947407!5m2!1sen!2sin"
            style={{ border: 0, width: '100%', height: '100%' }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
        <div className="inquiry-form">
          <h3>Inquiry Form</h3>
          <form ref={form} onSubmit={handleSubmit}>
            <input type="text" name="user_name" placeholder="Name" required />
            <input type="email" name="user_email" placeholder="Email Address" required />
            <input type="text" name="user_phone" placeholder="Your Phone" />
            <textarea name="message" placeholder="Message" required></textarea>
            <button type="submit">Send Message</button>
          </form>
        </div>
      </div>
    </section>
  );
}

export default Contact;
