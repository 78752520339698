import React, { useEffect } from 'react';
import './About.css'; // Import the CSS for styling

const About = () => {
  useEffect(() => {
    const aboutSection = document.querySelector('.about-us');

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('in-view');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 } // Adjust the threshold as needed
    );

    observer.observe(aboutSection);

    return () => {
      observer.unobserve(aboutSection);
    };
  }, []);

  return (
    <section id="about" className="about-us">
      <div className="container">
        <h2>Welcome to FormCraft – Your Premier Exhibition Stand Partner!</h2>
        <p>At FormCraft, we specialize in designing and constructing innovative and captivating exhibition stands that make your brand stand out. With years of experience in the industry, we understand the importance of creating a memorable and impactful presence at trade shows, exhibitions, and events.</p>
        <p>Our team of skilled designers and craftsmen work closely with you to bring your vision to life. We offer a comprehensive range of services, including custom stand design, fabrication, installation, and project management. Whether you need a small modular stand or a large bespoke structure, we have the expertise and resources to deliver exceptional results.</p>
        <p>At FormCraft, we are committed to excellence and customer satisfaction. Our goal is to provide you with a seamless and stress-free experience, ensuring that your exhibition stand not only meets but exceeds your expectations. Let us help you create an unforgettable experience for your audience and leave a lasting impression.</p>
        <p>Discover the FormCraft difference and elevate your brand with our stunning exhibition stands. Contact us today to discuss your next project!</p>
      </div>
    </section>
  );
}

export default About;
