import React, { useEffect } from 'react';
import './Gallery.css';
import './Style.css';

// Dynamically require all images from the elements directory
const importAll = (r) => r.keys().map(r);
const images = importAll(require.context('../elements/gallery', false, /\.(jpeg|jpg|png)$/));

function Gallery() {
  useEffect(() => {
    const galleryTiles = document.querySelectorAll('.gallery-tile-container');

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('in-view');
            observer.unobserve(entry.target);
          } else {
            entry.target.classList.remove('in-view');
          }
        });
      },
      { threshold: 0.1 } // Adjust the threshold as needed
    );

    galleryTiles.forEach(tile => {
      observer.observe(tile);
    });

    return () => {
      galleryTiles.forEach(tile => {
        observer.unobserve(tile);
      });
    };
  }, []);

  return (
    <section id="gallery" className="gallery">
      <h2>Gallery</h2>
      <div className="gallery-grid">
        {images.map((image, index) => (
          <div key={index} className="gallery-tile-container animated">
            <img src={image} alt={`Gallery ${index + 1}`} className="gallery-tile" />
          </div>
        ))}
      </div>
    </section>
  );
}

export default Gallery;
