import React, { useState, useCallback, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';
import './Style.css';  // Import common styles
import logo from '../elements/form_craft.jpg';

const Header = ({ sectionsRefs }) => {
  const [activeLink, setActiveLink] = useState('home');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const location = useLocation();

  const handleScroll = useCallback(() => {
    const scrollPosition = window.scrollY + window.innerHeight / 3;
    let activeId = 'home';
    Object.entries(sectionsRefs).forEach(([id, ref]) => {
      if (ref.current) {
        const { offsetTop, offsetHeight } = ref.current;
        if (scrollPosition >= offsetTop && scrollPosition < offsetTop + offsetHeight) {
          activeId = id;
        }
      }
    });
    setActiveLink(activeId);
  }, [sectionsRefs]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    if (location.pathname === '/') {
      setActiveLink('home');
    } else if (location.pathname === '/contact') {
      setActiveLink('contact');
    }
  }, [location]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <header className="header">
      <div className="header-container container">
        <div className="logo">
          <img src={logo} alt="FormCraft Logo" />
        </div>
        <button className="mobile-menu-button" onClick={toggleMobileMenu}>
          <span></span>
          <span></span>
          <span></span>
        </button>
        <nav className={`navbar ${isMobileMenuOpen ? 'open' : ''}`}>
          {Object.keys(sectionsRefs).map((id) => (
            <Link
              key={id}
              to={`/#${id}`}
              className={activeLink === id ? 'active' : ''}
              onClick={() => { setActiveLink(id); closeMobileMenu(); }}
            >
              {id.toUpperCase().replace('_', ' ')}
            </Link>
          ))}
          <Link
            to="/contact"
            className={activeLink === 'contact' ? 'active' : ''}
            onClick={() => { setActiveLink('contact'); closeMobileMenu(); }}
          >
            CONTACT US
          </Link>
        </nav>
      </div>
    </header>
  );
};

export default Header;
