import React, { useEffect } from 'react';
import './Style.css';
import './Services.css'; // Ensure this CSS is created and imported correctly

function Services() {
  useEffect(() => {
    const serviceBoxes = document.querySelectorAll('.service-box');

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('in-view');
          } else {
            entry.target.classList.remove('in-view');
          }
        });
      },
      { threshold: 0.1 } // Adjust the threshold as needed
    );

    serviceBoxes.forEach(box => {
      observer.observe(box);
    });

    return () => {
      serviceBoxes.forEach(box => {
        observer.unobserve(box);
      });
    };
  }, []);

  return (
    <section id="services" className="services-section">
      <h2>Our Services</h2>
      <div className="services-grid">
        <div className="service-box">
          <i className="fa fa-paint-brush service-icon"></i>
          <h3>Custom Stand Design</h3>
          <p>Tailored designs that reflect your brand’s unique identity.</p>
        </div>
        <div className="service-box">
          <i className="fa fa-cogs service-icon"></i>
          <h3>Fabrication</h3>
          <p>Precision-crafted stands built to impress.</p>
        </div>
        <div className="service-box">
          <i className="fa fa-wrench service-icon"></i>
          <h3>Installation</h3>
          <p>Seamless setup for a hassle-free experience.</p>
        </div>
        <div className="service-box">
          <i className="fa fa-tasks service-icon"></i>
          <h3>Project Management</h3>
          <p>End-to-end management to ensure everything runs smoothly.</p>
        </div>
        <div className="service-box">
          <i className="fa fa-pencil-alt service-icon"></i>
          <h3>Graphic Design</h3>
          <p>Eye-catching visuals to enhance your stand.</p>
        </div>
        <div className="service-box">
          <i className="fa fa-cubes service-icon"></i>
          <h3>Modular Stands</h3>
          <p>Flexible solutions for any event size.</p>
        </div>
        <div className="service-box">
          <i className="fa fa-cube service-icon"></i>
          <h3>Bespoke Structures</h3>
          <p>One-of-a-kind creations that make a statement.</p>
        </div>
        <div className="service-box">
          <i className="fa fa-truck service-icon"></i>
          <h3>Logistics</h3>
          <p>Efficient handling and transportation of your stand.</p>
        </div>
        <div className="service-box">
          <i className="fa fa-headset service-icon"></i>
          <h3>On-site Support</h3>
          <p>Expert assistance during the event.</p>
        </div>
      </div>
    </section>
  );
}

export default Services;
