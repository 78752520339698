import React from 'react';
import './Why.css';
import './Style.css';

function Why() {
  return (
    <section id="why" className="why">
      <div className="why-content">
        <h2>Why Choose Us?</h2>
        <ul>
          <li><span className="tick">✔</span><span className="text">Innovative Designs: Cutting-edge and creative stand designs that captivate and engage.</span></li>
          <li><span className="tick">✔</span><span className="text">Customization: Tailored solutions to meet your specific brand needs and event goals.</span></li>
          <li><span className="tick">✔</span><span className="text">Quality Craftsmanship: High-quality materials and meticulous attention to detail in every build.</span></li>
          <li><span className="tick">✔</span><span className="text">Comprehensive Services: End-to-end services from design to installation and beyond.</span></li>
          <li><span className="tick">✔</span><span className="text">Experienced Team: A skilled team of designers, craftsmen, and project managers with years of industry experience.</span></li>
          <li><span className="tick">✔</span><span className="text">Customer-Centric Approach: Dedicated to providing exceptional customer service and satisfaction.</span></li>
          <li><span className="tick">✔</span><span className="text">On-Time Delivery: Reliable and timely delivery of projects, ensuring your stand is ready for the event.</span></li>
          <li><span className="tick">✔</span><span className="text">Sustainability: Eco-friendly practices and materials to minimize environmental impact.</span></li>
          <li><span className="tick">✔</span><span className="text">Global Reach: Ability to handle projects for exhibitions and events worldwide.</span></li>
          <li><span className="tick">✔</span><span className="text">Competitive Pricing: High-quality services at competitive prices to fit your budget.</span></li>
        </ul>
      </div>
      <p className="why-footer">Choose FormCraft for a seamless and impactful exhibition experience!</p>
    </section>
  );
}

export default Why;
