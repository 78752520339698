import React, { useRef, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import Why from './components/Why';
import Projects from './components/Projects';
import Gallery from './components/Gallery';
import Contact from './components/Contact';
import Footer from './components/Footer';
import './components/Style.css';  // Import common styles

const App = () => {
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const servicesRef = useRef(null);
  const whyRef = useRef(null);
  const projectsRef = useRef(null);

  const sectionsRefs = {
    home: homeRef,
    about: aboutRef,
    services: servicesRef,
    why: whyRef,
    projects: projectsRef,
  };

  const MainContent = () => (
    <>
      <div ref={homeRef} id="home"><Home /></div>
      <div ref={aboutRef} id="about"><About /></div>
      <div ref={servicesRef} id="services"><Services /></div>
      <div ref={whyRef} id="why"><Why /></div>
      <div ref={projectsRef} id="projects"><Projects /></div>
    </>
  );

  const Layout = ({ children }) => (
    <>
      <Header sectionsRefs={sectionsRefs} />
      {children}
      <Footer />
    </>
  );

  const ScrollToSection = () => {
    const location = useLocation();
    useEffect(() => {
      if (location.hash) {
        const element = document.querySelector(location.hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }, [location]);

    return null;
  };

  return (
    <Router>
      <ScrollToSection />
      <Routes>
        <Route path="/" element={<Layout><MainContent /></Layout>} />
        <Route path="/gallery" element={<Layout><Gallery /></Layout>} />
        <Route path="/contact" element={<Layout><Contact /></Layout>} />
      </Routes>
    </Router>
  );
};

export default App;
